<template>
	<div class="card card-custom card-stretch gutter-b bg-danger">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">TODO: {{props.html }}</h3>
		</div>
		<div class="card-body pt-2">
			{{props}}
		</div>
	</div>
</template>

<script>
export default {
	name: 'Site',
	props: {
		props: {
			type: Object,
			required: true,
		},
		device: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			DeviceType: null,

		};
	},


};
</script>

<style>
</style>